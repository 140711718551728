import React from 'react'
import { Link } from 'react-router-dom';
import classes from './NavLink.module.css';

const navLink = (props) => {
    
    return (
        <Link 
            to={props.to}
            className={(props.pathname === props.to) ? classes.Active : null}
        >
                {props.children}
        </Link>
    )
}

export default navLink

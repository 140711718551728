import React from 'react'

import CoronaImg1 from '../../../assets/media/img/Corona/IMG_20200914_194306.jpg'
import CoronaImg2 from '../../../assets/media/img/Corona/IMG_7728.JPG'

const corona = () => {
    return (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            {/* 1ggWXaM6aikAt4Sgyt1kLpAyw7Vxrz7Cy */}
            <h2 className="classicalFont">Hygienemaßnahmen</h2>
            <div style={
                {width: '90%',
                 height: '70vh',
                 maxWidth: '1024px'}}>
                    {/* <iframe title="coronaRules" src={pdfFileRules} style={{width:'100%', height: '100%'}}></iframe> */}
                    <iframe title="coronaRules" src="https://drive.google.com/file/d/1PzhM4AP1aISWCA_ACR5AsG3fYMmS5cBK/preview" style={{width:'100%', height: '100%'}}></iframe>
            </div>
            <hr />
            <h2 className="classicalFont" style={{marginTop: '2rem'}}>Pressemitteilung</h2>
            <div style={
                {width: '90%',
                 height: '70vh',
                 maxWidth: '1024px'}}>
                    {/* <iframe  title="coronaPress" src={pdfFilePress} style={{width:'100%', height: '100%'}}></iframe> */}
                    <iframe title="coronaPress" src="https://drive.google.com/file/d/1jepqCnz48k3PkF6WCxjvMuQkmka0tjOt/preview" style={{width:'100%', height: '100%'}}></iframe>
            </div>

            <h2 className="classicalFont" style={{marginTop: '2rem'}}>Vorgaben Schleswig-Holstein</h2>
            <div style={
                {width: '90%',
                 height: '70vh',
                 maxWidth: '1024px'}}>
                <iframe title="CoronaSH" src="https://drive.google.com/file/d/1pJOm2XujGI-d3acI9EGeQC_ILJIFHxDg/preview" style={{width:'100%', height: '100%'}}></iframe>
            </div>

            <h2 className="classicalFont" style={{marginTop: '2rem'}}>Proben in Zeiten von Covid-19</h2>
            <div style={
                {width: '90%',
                 maxWidth: '1024px'}}>
                <img src={CoronaImg1} alt="Aufnahme Probe" style={{width: '100%', borderRadius: '5px', boxShadow: '0 1px 5px #00000080', marginBottom: '3rem'}}/>
                <img src={CoronaImg2} alt="Aufnahme Probe" style={{width: '100%', borderRadius: '5px', boxShadow: '0 1px 5px #00000080', marginBottom: '3rem'}}/>
            </div>
        </div>
    )
}

export default corona

import React from 'react'
import { Zoom } from 'react-slideshow-image'
import 'react-slideshow-image/dist/styles.css'

const slideshow = (props) => {
    const zoomInProperties = {
        indicators: true,
        scale: 1.4
    }

    return (
            <Zoom {...zoomInProperties}>
                {props.images.map((each, index) => (
                    <div key={index} style={{width: "100%", maxHeight: "1000px", display: "flex", justifyContent: "center"}}>
                        <img style={{objectFit: "contain", width: "100%"}} src={each} alt={"SlideshowImage"+index} />
                    </div>
                ))}
            </Zoom>
    )
}

export default slideshow

import React from 'react'
import classes from './Impressum.module.css'

const impressum = () => {
    return (
        <div className={classes.Impressum}>
            <h1 className="classicalFont">Impressum</h1>
            <p><strong>Angaben gemäß § 5 TMG</strong><br/>
                Cantica Neumünster<br/>
                <strong>Vertreten durch den Vorstand:</strong><br/>
                Gabriele Vach – Vorsitzende<br/>
                Tel. 04321 66187<br/>

                <strong>Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:</strong><br/>
                Vorsitzende Gabriele Vach<br/>
                Tel. 04321 66187</p>

            <h3>Disclaimer</h3>
            <p>Diese Website wurde unter Beachtung aller gebotenen und zumutbaren Sorgfalt erstellt. Eine Garantie für die Richtigkeit, Vollständigkeit und Aktualität der bereitgestellten Inhalte kann aber gleichwohl nicht übernommen werden. Ansprüche wegen falscher, unvollständiger oder nicht mehr aktueller Angaben sind daher – aus welchem Rechtsgrund auch immer – ausgeschlossen.</p>
            <p>Die Website kann namentlich gekennzeichnete Beiträge enthalten. Diese geben ausschließlich die Meinung des jeweiligen Autors wieder, die nicht mit der Meinung des Anbieters übereinstimmen muss.</p>
            <p>Diese Website kann Verknüpfungen zu anderen Websites („externe Links“) enthalten. Für diese anderen Websites und ihre jeweiligen Inhalte haften ausschließlich die Betreiber der anderen Websites nach Maßgabe der für sie gültigen Bestimmungen.</p>
            <p>Der Anbieter dieser Website hat zwar bei der erstmaligen Verknüpfung der externen Links die verlinkten Websites auf rechtliche Bedenken überprüft und nichts feststellen können. Damit konnte aber nur der Zustand bei erstmaliger Herstellung der Verknüpfung überprüft werden. Soweit der festgestellte ordnungsgemäße Zustand in Zukunft nicht mehr erhalten bleiben sollte, haftet der Betreiber der Website hierfür nicht. Mit dem Setzen externer Links ist keine Befürwortung der Inhalte der anderen Websites verbunden.</p>
            <p>Unberührt hiervon bleibt die Tatsache, dass externe Links durch den Anbieter dann gelöscht werden, wenn der Anbieter positive Kenntnis von Rechtsverstößen auf den anderen Websites erhält.</p>
            <p>Der Anbieter weist ausdrücklich auf die Vorschriften des deutschen Urheber- und Leistungsschutzrechts hin. Jegliche Verwertung der Inhalte dieser Website bedarf, soweit nicht nach den Regelungen des deutschen Urheber- und Leistungsschutzrechts eine Ausnahme hiervon besteht, der vorherigen schriftlichen Zustimmung des Anbieters oder des sonstigen Rechteinhabers (siehe oben). Ohne eine derartige schriftliche vorherige Erlaubnis ist allein die Herstellung von Kopien und Downloads für eine persönliche private und nicht kommerzielle Nutzung erlaubt.</p>
            <p>Auch die Darstellung dieser Website in fremden Frames ist nur mit vorheriger schriftlicher Erlaubnis des Anbieters gestattet. Gleiches gilt für eine Nutzung der Kontaktdaten des Impressums zu gewerblichen Zwecken, soweit nicht bereits eine Geschäftsbeziehung zum Anbieter besteht.</p>
            <hr/>
            <h3>Datenschutz</h3>
            <p>Der Chor Cantica Neumünster nimmt den Schutz personenbezogener Daten seiner Mitglieder und seiner Partner ernst; er hat durch technische und organisatorische Maßnahmen sichergestellt, dass die gesetzlichen Vorschriften über den Datenschutz sowohl von ihm als auch von externen Dienstleistern beachtet und eingehalten werden. Die Beachtung dieser Verpflichtung wird vom Verein regelmäßig kontrolliert. Die Erhebung, Verarbeitung, Nutzung und Weitergabe von Daten erfolgt zum einen mit Einverständnis des Dateninhabers, andererseits ausschließlich zum Zweck der Erfüllung der Pflichten des Vereins. Die Weitergabe an Dritte erfolgt nur aus zwingenden Gründen und im Interesse des Vereins. Das betroffene Vereinsmitglied hat jederzeit die Möglichkeit, sich über die Verwendung und den Verbleib seiner geschützten Daten zu informieren; er hat Anspruch auf Dokumentation der Einhaltung datenschutzrechtlicher Bestimmungen in Bezug auf ihn. Er hat das Recht, jederzeit eine erteilte Einwilligung zu widerrufen und die Löschung seiner Daten zu verlangen, Art. 17 DS-GVO.</p>
            <p>Partner des Vereins und Dritte werden durch die Einhaltung der Datenschutzbestimmungen durch die Verantwortlichen des Vereins in gleicher Weise geschützt. Es findet kein Verkauf oder keine unentgeltliche Weitergabe von Daten Dritter oder Partner des Vereins statt, es sei denn, es läge eine entsprechende Einwilligung vor.</p>
            <p>Bei der Einschaltung externer Dienstleister, denen personenbezogene Daten zur Verfügung gestellt werden müssen, ist durch Abschluss eines entsprechenden Vertrages sichergestellt, dass die Datenschutzbestimmungen in gleicher Weise auch vom beauftragten Unternehmen eingehalten werden.</p>
            <p>Im Fall des Widerrufs oder der Anzeige von falsch erhobenen Daten werden diese sofort gelöscht, Art. 21, 18 DS-GVO. Auf das Beschwerderecht bei einer Datenschutzaufsichtsbehörde (Art. 77 DS-GVO i. V. m. § 19 BDSG) wird ausdrücklich hingewiesen. Für Cantica Neumünster zuständig ist das Unabhängige Landeszentrum für Datenschutz Schleswig-Holstein, Holstenstraße 98, <br/>24103 Kiel</p>
            <p>Für Datenschutz und Datenverarbeitung in unserem Verein Verantwortlich ist der Vorstand, siehe Impressum.</p>
        </div>
    )
}

export default impressum

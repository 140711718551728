import React from 'react'
import classes from './CardImageText.module.css';


const cardImageText = (props) => {
    const activeClass = props.showAll ? classes.Card__complete : classes.Card__small;

    return (
        <div className={classes.Card}>
            <img className={classes.Card__image} src={props.image} alt="cardImage"/>
            <div className={[classes.Card__body, activeClass].join(' ')}>
                <h5 className={classes.Card__title}>{props.title}</h5>
                <p className={classes.Card__text}>{props.text}</p>
            </div>

            {props.showAll ? (<div className={classes.Card__more} ><span onClick={(e) => props.toggleShowComplete(e, props.index)}>...Weniger</span></div>) : (<div className={classes.Card__more} ><span onClick={(e) => props.toggleShowComplete(e, props.index)}>...Mehr</span></div>)}
        </div>
    )
}

export default cardImageText

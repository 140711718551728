import classes from './Media.module.css'
import React from 'react'

import Slideshow from '../../Slideshow/Slideshow'
import AudioSection from './AudioSection/AudioSection'

import {galleryImages} from '../../../containers/ImageHandler'
import VideoSection from '../../pages/Home/VideoSection/VideoSection'

// function importAll(r) {
//     return r.keys().map(r);
//   }

// const galleryImages = importAll(require.context('../../../assets/media/img/gallery'))

const media = (props) => {
    return (
        <div className={classes.Media}>
            <div className={classes.Media__slideshow}>
                <h1 className="classicalFont">Galerie</h1>
                <Slideshow images={galleryImages} />
            </div>
            <div className={classes.Media__audio}>
                <h1 className="classicalFont">H&ouml;rbeispiele</h1>
                <AudioSection />
            </div>
            <div className={classes.Media__video}>
                <VideoSection 
                    videoSection={props.videoSection} 
                    mute={props.mute} 
                    forceDisplay="true"/>
            </div>
        </div>
    )
}

export default media

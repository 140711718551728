import React from 'react'
import Banner from '../../Banner/Banner';
import Card from '../../CardImageText/CardImageText'
import AboutUs from './AboutUs/AboutUs';
import VideoSection from './VideoSection/VideoSection';

import { cardSection } from '../../../containers/ImageHandler';


import classes from './Home.module.css'

const home = (props) => {
    return (
        <div>
           <Banner />
           <section className={classes.CardDeck}>
                <Card image={cardSection[1]}
                    title="Leidenschaft"
                    showAll={props.home.showCompleteCard[1]}
                    text="Im Wort Leidenschaft ist nicht ganz unbeabsichtigt auch das Wort „Leiden“ enthalten. Ja, wie auch bei jedem anderen Chor gehört auch das dazu, wenn wir versuchen, jedem Chorwerk oder Lied musikalisch gerecht zu werden. Das braucht Zeit, Geduld und viele Proben. Manchmal erleben wir auch Stillstand und dann löst sich doch plötzlich der Knoten. Das verbindet ungemein, es macht einfach Spaß. Am Ende sind die Leiden in Leidenschaft übergegangen, die sich dann auf unser Publikum überträgt und der Applaus alle Mühen vergessen lässt. Dann sind wir eins, Cantica, unvergesslich, eine Gemeinschaft, die unter der charismatischen Leitung ihres Chorleiters zu Höchstleistungen in der Lage ist. Immerhin, wir sind alle zusammen musikalische Laien. Umso mehr sind wir dann stolz auf unsere Leistung , die aus der Leidenschaft geboren wurde." 
                    toggleShowComplete={props.toggleShowComplete}
                    index="1"
                    />
                <Card image={cardSection[0]}
                    showAll={props.home.showCompleteCard[0]}
                    title="Qualit&auml;t"
                    text="Wir lieben es, wenn wir immer wieder dazu angehalten werden, deutlich und akzentuiert zu singen, der Intention des Komponisten gerecht zu werden, seine oder ihre Musik so zu interpretieren, wie sie gemeint ist . Das ist nicht immer leicht, aber es ist unser Anspruch jedes Werk so erklingen zu lassen, dass wir seiner musikalischen Botschaft gerecht werden können. Wir lieben es auch, uns mit Komponisten zu befassen, die vielleicht noch nicht so bekannt sind, aber deren Musik von einnehmender Qualität ist und in uns selbst und bei unserem Publikum Emotionen, Tiefe und Begeisterung auslösen kann. Ob geistliche Werke, Folklore oder auch zeitgenössische Musik bekannter Liedermacher und Komponisten bekannter Filmmusik, unser Repertoire soll authentisch, ehrlich und einfach überzeugend gut herüberkommen. Das ist unser Anspruch an gelebte Qualität." 
                    toggleShowComplete={props.toggleShowComplete}
                    index="0"
                    />
                <Card image={cardSection[2]}
                    showAll={props.home.showCompleteCard[2]}
                    title="Freude"
                    text="Wir sind eine Gemeinschaft, die teilweise schon eine lange Strecke gemeinsam musikalisch gegangen ist. Wir tun das, weil es uns Spaß macht, weil Singen befreit. Perfekt sind wir sicher nicht. Vor allem sind wir aber offen für Neues und für Neue in unserer Gemeinschaft. Wir unternehmen auch andere Dinge zusammen, gehen auf Freizeiten, lachen und weinen zusammen. Ach ja, feiern können wir auch ganz gut. Manchmal arbeiten wir auch mit anderen Chören zusammen. So lernen wir immer wieder andere Menschen kennen und zehren dann lange von Erfolgen und wunderschönen Stunden in einem musikalischen Rahmen, der uns nachhaltig prägt, Kraft gibt und damit zur Lebensfreude beiträgt. Wir wollen, dass unsere Herzlichkeit ansteckt und andere mitreißt. Bei uns ist jeder willkommen, der gerne singt. Hauptsache, es macht Freude." 
                    toggleShowComplete={props.toggleShowComplete}
                    index="2"
                    />
           </section>
           
           <section className={classes.Home__aboutUs}>
               <AboutUs />
           </section>

            <section className={classes.Home__videoSection}>
                <VideoSection videoSection={props.videoSection} mute={props.mute}/>
            </section>
        </div>
    )
}

export default home;
import React from 'react'
import classes from './ChoirDirection.module.css'
import choirDirectionImage from '../../../assets/media/img/Klose.jpg'
import ExampleList from './ExampleList/ExampleList'

const choirDirection = () => {
    return (
        <div className={classes.ChoirDirection}>
            <h1 className={[classes.ChoirDirection__headline,"classicalFont"].join(' ')}>Norbert Klose</h1> 
            <img className={classes.ChoirDirection__image} src={choirDirectionImage} alt="ChorleitungKlose" />    
            <p className={classes.ChoirDirection__detail}>Norbert Klose, in Husum geboren, studierte nach dem Abitur an der
                Musikhochschule Lübeck Kirchenmusik und schloss 1989 mit dem
                B-Examen ab. Seit 1990 ist er Kantor und Organist an der Peter-Pauls-
                Kirche in Hohenwestedt und seit 1995 Chorleiter von „Cantica Neumünster“.
                <br />
                Sein Faible ist die Entdeckung und Bearbeitung von Partituren, die in
                Bibliotheken aufbewahrt aber nicht gespielt werden. So hat er seine Chöre zu Uraufführungen oder ersten Wiederaufführungen geleitet.
            </p>
            
            <ExampleList />
            
        </div>
    )
}

export default choirDirection

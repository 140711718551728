import React from 'react'
import classes from './ChronicalChoirDirection.module.css'

const chronicalChoirDirection = (props) => {
    const isWhite = (props.color === 'wh') ? classes.wh : null;
    
    return (
        <div className={[classes.ChronicalChoirDirection, isWhite].join(' ')}>
            <div className={[classes.ChronicalChoirDirection__container, isWhite].join(' ')}>
                <div className={[classes.ChronicalChoirDirection__shorthand, isWhite].join(' ')}>
                    <h3>{props.name}</h3>
                    <span>{props.date}</span>
                </div>
                <div className={classes.ChronicalChoirDirection__detail}>
                    {props.children}
                </div>
            </div>
        </div>
    )
}

export default chronicalChoirDirection

import React from 'react';
import {Link} from 'react-router-dom';

import classes from './Banner.module.css'

import choirDirectorImg from '../../assets/media/img/choir-director.png';

const banner = () => {
    return (
        <div className={classes.Banner}>
            <div className={classes.Banner_textContainer}>
                <p className={classes.Banner__strechedHeadline}>Cantica</p>
                <p>Neum&uuml;nster</p>
            </div>
           <Link to="/practice" className={classes.Banner__callToAction}>Sing mit uns</Link>

           
            <img className={[classes.Banner__choirDirectorImage,classes.clearfix].join(' ')} 
            src={choirDirectorImg} 
            alt="Chorleitung"/>
        </div>
    );
};

export default banner;
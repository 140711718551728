import React from 'react'

import classes from './AboutUs.module.css';

import aboutUsImage from '../../../../assets/media/img/Pleasure.jpg';

const aboutUs = () => {
    return (
            <div className={classes.AboutUsContent}>
                    <h1>Wir sind Cantica!</h1>
                    <img className={classes.AboutUsImage} src={aboutUsImage} alt="Choraufstellung" />
                    <p>Wir freuen uns über Deinen Besuch.</p>
                    <p>Cantica Neumünster ist eine lebendige Chorgemeinschaft mit vielseitigem Spektrum anspruchsvoller Chormusik, die sich stetig weiter entwickelt hat. Heute werden die 35 Sänger*innen aus Neumünster und Umgebung engagiert und leidenschaftlich von Norbert Klose (Kirchenmusiker an der Peter-Pauls-Kirche Hohenwestedt) geleitet. Das Repertoire unseres Chores ist weit gefächert. Eine ausgewogene Mischung aus interessant gesetzten Stücken weltlicher und geistlicher Literatur, bis zu „Klassikern“ bekannter Hollywoodfilme und Perlen der klassischen Musik sowie selten aufgeführte Werke halten die Probenarbeit lebendig.</p>
                    <p>Im Jahre 1983 gegründet, ist unser Chor heute ein eingetragener Verein, der dem VDKC (Verband deutscher Konzertchöre) angehört. Seit 5 Jahren probt die Chorgemeinschaft im Gemeindehaus der Bugenhagenkirche Neumünster und hat dort ein angenehmes Zuhause gefunden. Für die Erarbeitung großer Werke wie Oratorien, Messen und der Luther-Oper gibt es Kooperationen mit anderen unterschiedlichen Chören, Chorleitern und Orchestern, die daraus entstandenen Kontakte bereichern das Chorleben sehr. Neben dem gemeinsamen Singen pflegen wir die Geselligkeit, je nach Interesse durch Unternehmungen, Chorwochenenden und, in größeren Abständen, auch Chorreisen.</p>
               </div>
    )
}

export default aboutUs

import React from 'react'

import classes from './Practice.module.css'
const practice = () => {
    return (
        <div className={classes.Practice}>
            <h1 className={[classes.Practice__title, "classicalFont"].join(' ')}>Proben</h1>
            <p>Unsere Proben finden jeweils <strong>montags</strong> statt,</p>
            <p>
                von <strong>19.30- 21.30 Uhr</strong>
            </p>
            <p>
                im <strong>Gemeindehaus - Bugenhagen</strong>
            </p>
                <address>
                    Hansaring 146, 
                    <br />
                    24534 Neumünster
                </address>
            <p>
                {/* <strong>Im Moment fallen auf Grund der aktuellen Ereignisse unsere Chorproben bis auf Weiteres aus!</strong> */}
            </p>
            {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d9363.632305969539!2d9.956533233785027!3d54.07537352208003!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47b24ba576442be9%3A0xb1d718772d72051c!2sEv.-Luth.%20Bugenhagen-Kirchengemeinde%20Neum%C3%BCnster!5e0!3m2!1sde!2sde!4v1632979477837!5m2!1sde!2sde" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe> */}
            <iframe className={classes.Practice__maps}
                    title="practiceFacility" 
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d9363.632305969539!2d9.956533233785027!3d54.07537352208003!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47b24ba576442be9%3A0xb1d718772d72051c!2sEv.-Luth.%20Bugenhagen-Kirchengemeinde%20Neum%C3%BCnster!5e0!3m2!1sde!2sde!4v1632979477837!5m2!1sde!2sde" 
                    width="600" 
                    height="450" 
                    frameBorder="0" 
                    style={{border:0}} 
                    allowFullScreen="" 
                    aria-hidden="false" 
                    tabIndex="0"
            />
            {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2341.5185780045845!2d9.959972915865688!3d54.06454638013587!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47b24a4d55011853%3A0x597c0969a75e3c7!2sGemeinschaftsschule%20Faldera!5e0!3m2!1sde!2sde!4v1601301145523!5m2!1sde!2sde" width="600" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe> */}
        </div>
    )
}

export default practice

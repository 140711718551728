import React from 'react'
// import Playlist from 'react-mp3-player';
import audio from '../../../../containers/Mp3Handler'

const audioSection = () => {
    const tracks = audio.map((track) => {
        let name = track.split('/');
        name = name[name.length-1].split('.');
        return(
        <li key={track} style={{marginBottom: '1rem'}}>
            <audio controls >
                <source src={track} type="audio/mpeg"/>
            </audio>
            <p style={{marginTop: "0", marginBottom: '2rem'}}>{name[0]}</p>
        </li>
        )
    })
    return (
        <div>
            <ul>
                {tracks}
            </ul>
        </div>
    )
}

export default audioSection

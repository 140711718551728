import React from 'react';
import { HashRouter as Router } from 'react-router-dom';

import Navigation from './components/Navigation/Navigation';
import PageHandler from './containers/PageHandler';
import Footer from './components/Footer/Footer'

import CoronaNote from './components/CoronaNote/CoronaNote'

import './App.css';

class App extends React.Component {
  state = { 
    videoSection: {
      muted: true,
      buttonText: 'Ton an',
    },
    home: {
      showCompleteCard: [false, false, false]
    }
  }

  mute = () =>{
    const currentMuteState = this.state.videoSection.muted;
    const buttonText = (this.state.videoSection.muted) ? 'Ton aus' : 'Ton an';

    this.setState({
      videoSection: {
        muted: !currentMuteState,
        buttonText: buttonText
      }
    });
  }

  toggleShowComplete = (e, index) => {
    const newState = {...this.state.home.showCompleteCard};
    newState[index] = !newState[index];
    this.setState({ home: {
        showCompleteCard: newState}
    })
  }

  render(){
  return (
      <div className="App">
        <Router >
          <div>
            
            <Navigation />
            {/* <CoronaNote /> */}
            <PageHandler 
              videoSection={this.state.videoSection} 
              mute={this.mute} 
              home={this.state.home}
              toggleShowComplete={this.toggleShowComplete}
            />

          </div>
        <Footer />
        </Router>
      </div>
    );
  }
}

export default App;

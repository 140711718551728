const pages = [
    {
        name: 'Home',
        path: '/'
    },
    {
        name: 'Chor',
        path: '/choir'
    },
    {
        name: 'Probenplan',
        path: '/practice'
    },
    {
        name: 'Chorleitung',
        path: '/choir-director'
    },
    {
        name: 'Medien',
        path: '/media'
    },
    {
        name: 'Aktuelles',
        path: '/news'
    }
]

export default pages
import React from 'react'
import classes from './Footer.module.css'
import {Link} from 'react-router-dom'

const footer = () => {
    return (
        <div className={classes.Footer}>
            <div className={classes.Footer__cantica}>
                <div>
                    <span><strong>Email:</strong> <br /><a href="mailto:vachs@gmx.de">vachs@gmx.de</a></span>
                </div>
                <div>
                    <span><strong>Proben:</strong> <br />montags - 19.30 - 21.30 Uhr</span>
                </div>
                <div>
                    <strong>Adresse:</strong>
                    <address>
                        Hansaring 146<br />
                        24534 Neumünster
                    </address>
                </div>
            </div>
            <div className={classes.Footer__tg}>
                <span>Design by <a href="https://tg-webdesign.de" target="_blank" rel="noopener noreferrer">TG Webdesign</a>  &copy;{new Date().getFullYear()}</span>
                <br/>
                <span>Content by Cantica Neum&uuml;nster</span>
                <Link to="/impressum">Impressum</Link>
            </div>
        </div>
    )
}

export default footer

import React, { useState } from 'react'
import { withRouter } from 'react-router-dom';
import NavLink from './NavLink/NavLink'
import { FaBars, FaTimes } from 'react-icons/fa'; 

import pages from './NavigationConfig';

import classes from './Navigation.module.css';

const Navigation = (props) => {
    const [click, setClick] = useState(false);
    const [activeClasses, setActiveClasses] = useState([classes.Navigation__listItem])

    const toggleHandler = () => {
        (click) ? setActiveClasses([classes.Navigation__listItem]) : setActiveClasses([classes.Navigation__listItem, classes.active]);
        
        setClick(!click);
    }

    const links = pages.map((page,index) => (
        <li key={page.name+index} className={activeClasses.join(' ')} onClick={toggleHandler}>
            <NavLink to={page.path} pathname={props.location.pathname} >{page.name}</NavLink>
        </li>
    ))

    
    return (
        <nav className={classes.Navigation}>
            <ul className={classes.Navigation__listRoot}>
               <li className={classes.Navigation__logo}>
                    <NavLink to="/">Cantica</NavLink>
                </li>
                {links}
                <li className={classes.Navigation__toggle} onClick={toggleHandler}>
                    {click ? <FaTimes /> : <FaBars />}
                </li>
            </ul>
        </nav>
    )
}

export default withRouter(Navigation)

import React from 'react'

import classes from './VideoSection.module.css';

import motivationVideo from '../../../../assets/media/video/Cantica Atrium.mp4'

const videoSection = (props) => {
    const currentProps = {...props};
    const muted=(currentProps.videoSection.muted) ? "muted" : null;
    const display = (props.forceDisplay === "true") ? {display: "flex", width: "100%"} : null; 

    return (
        <div style={display} className={classes.VideoSection}>
            <video 
                playsInline="playsinline" 
                autoPlay="autoplay"
                muted={muted}
                loop="loop"
                style={{width: "100%"}}>
                    <source src={motivationVideo} type="video/mp4"></source>
                </video>

            <div className={classes.VideoSection__overlayTextInnerContainer}>
                <h1>Hör doch mal rein!</h1>
                <p>Bei unserer spontanen Aktion im Atrium</p>
                <button className={classes.VideoSection__muteButton} onClick={props.mute}>{currentProps.videoSection.buttonText}</button>
            </div>
        </div>
    )
}

export default videoSection

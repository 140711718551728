import React from 'react'
import ChronicalChoirDirection from './ChronicalChoirDirection/ChronicalChoirDirection';

import classes from './ChronicalSection.module.css'

const chronicalSection = () => {
    return (
        <React.Fragment>
            <section className={classes.ChronicalSection}>
                <h1 className={classes.ChronicalSection__title}>Chronik des Chores Cantica Neum&uuml;nster</h1>
                <ul>
                    <li>Cantica Neum&uuml;nster wurde am 02. 09. 1983 von dem ehemaligen Musiklehrer der Elly-Heuss-Knapp-Schule, Richard Wolf, gegr&uuml;ndet.</li>
                    <li>Als Nachfolger konnte Gunther Ruddeck gewonnen werden, der am 21. 08. 1990 die Arbeit mit dem Chor aufnahm.</li>
                    <li>Durch einen gl&uuml;cklichen Zufall konnte der Kontakt zu Norbert Klose hergestellt werden, der ab Herbst 1995 die Chorleitung &uuml;bernahm.</li>
                </ul>
            </section>
            <ChronicalChoirDirection name="Norbert Klose" date="1995 - heute">
                <p>Durch einen glücklichen Zufall konnte der Kontakt zu <strong>Norbert Klose</strong> hergestellt werden, der ab Herbst 1995 die Chorleitung übernahm und die Vorbereitungen für das schon angemeldete Qualifikationssingen des Sängerbundes fortführte. Cantica erreichte wiederum den ersten Platz. Die Entwicklung zum Konzertchor ging weiter. Die „Carmina burana“ sowie große Oratorien und Messen wurden, teils in Gemeinschaft mit anderen Chören, erarbeitet und zu Gehör gebracht. Auch zeitgenössische Werke wie Dietrich Lohffs „Requiem für einen polnischen Jungen“ ergänzten das Repertoire, das sich heute je etwa zur Hälfte aus geistlichen und weltlichen Werken zusammensetzt. Folgerichtig gab der Chor die Bezeichnung  „Singkreis“ auf und trat als „Cantica Neumünster“ dem Verband Deutscher Konzertchöre (VDKC) bei.</p>
                <p>Zum 25–jährigen Jubiläum 2008 wurde in der Bugenhagenkirche Neumünster unser Jubiläumskonzert aufgeführt und danach im Restaurant Scheffler die Jubiläumsfeier abgehalten, die allen in angenehmer Erinnerung ist.</p>
                <p>Im Rahmen des Kunstfleckens 2009 war Cantica Veranstalter der Aufführung der Gospelrhapsodie „Our Father in Heaven“ von Ralf Grössler, ein großes Ereignis für Orchester,  4 Chöre (Cantica, Eltern-Lehrer-Chor der IGS Brachenfeld und Projektchöre aus Hohenwestedt und Lübeck) und die bekannte Solistin Joanne Bell.  Die Premiere fand am 05.September  in der Vicelinkirche  Neumünster statt und wurde mit „standing ovations“ gefeiert. Weitere Aufführungen folgten in Hohenwestedt und Lübeck .</p>
                <p>Unserem Dirigenten Norbert Klose gelingt es immer wieder, für die Chorarbeit  aus dem großen Musikfundus anspruchsvolle Literatur zusammen zu stellen und den Chor zu Höchstleistungen zu motivieren.  Aber auch das gesellige Leben im Chor kommt nicht zu kurz.  Es gibt Boßelveranstaltungen, Grünkohlessen und Fahrradausflüge in die nähere Umgebung. Darüber hinaus werden auch Konzertreisen durchgeführt, die sich großer Beliebtheit erfreuen.</p>
            </ChronicalChoirDirection>
            <ChronicalChoirDirection name="Gunther Ruddeck" date="1990 - 1995" color="wh">
                <p>Als Nachfolger konnte <strong>Gunther Ruddeck</strong> gewonnen werden, der am 21. 8. 1990 die Arbeit mit dem Chor aufnahm. Unter seiner Leitung wandelte sich das Repertoire erheblich. Bekannte a-capella-Werke aus der Zeit der Renaissance erarbeitete der Chor sich ebenso wie Werke der großen Komponisten der Romantik. Auf  folkloristischer Ebene bevorzugte Gunther Ruddeck moderne Sätze von Komponisten der Gegenwart, die möglichst in Originalsprache einstudiert wurden. Die Entwicklung von Cantica zum Konzertchor fand einen ersten Höhepunkt in der erfolgreichen Aufführung zweier chorsinfonischer Werke mit Orchesterbegleitung, dem „Gloria“ von Vivaldi und der „Missa brevis“ von Mozart.</p>
                <p>Bei einem Qualifikationssingen des Sängerbundes wurde ein erster Platz erreicht.</p>
                <p>Ein weiteres Merkmal der Ära Ruddeck waren zahlreiche Kontakte zu anderen Chören, die mit Reisen nach Berlin, Vorpommern und Mecklenburg verbunden waren. Gegenbesuche und gemeinsame Auftritte in Neumünster folgten. Mitte 1995 endete die Zeit mit Gunther Ruddeck.</p>
            </ChronicalChoirDirection>
            <ChronicalChoirDirection name="Richard Wolf" date="1983 - 1990">
                <p>Cantica Neumünster wurde am 2. 9. 1983 von dem ehemaligen Musiklehrer der Elly-Heuss-Knapp-Schule, <strong>Richard Wolf</strong>, gegründet.  Er nannte die Gruppe, die vermutlich vor allem aus ehemaligen Schülerinnen und Schülern bestand, „Chorgemeinschaft für gemischte Stimmen“.</p>
                <p>Die zunächst sieben Mitglieder sangen mehr zum eigenen Vergnügen, bis im Herbst 1985 ein Zeitungsaufruf  soviel Zulauf brachte, dass sich ein echter Chor konstituieren konnte.</p>
                <p>Ab 1. 3. 1986 nannte er sich dann „Singkreis Cantica Neumünster von 1983“.</p>
                <p>Am 16. 3. 1988 trat der junge Chor dem Sängerbund Schleswig-Holstein bei und wurde im Verlauf des Jahres 1989 nach Verabschiedung einer Satzung als Verein im Vereinsregister des Amtsgerichtes eingetragen.</p>
                <p>Der Schwerpunkt des Repertoires lag unter Richard Wolf auf der tänzerischen Chormusik. Der Chorleiter verstand darunter volksliedhafte, rhythmisch betonte Liedsätze aus aller Welt mit vorwiegend deutschen Texten. Dazu passte die folkloristische Chorkleidung, bunte, weite schwingende Röcke für die weiblichen Mitglieder, weiße Trachtenhemden für die Männer. Unter Richard Wolf hat sich die Chorgemeinschaft zu einem respektablen Chor entwickelt. Auf dem Landeschorwettbewerb 1989 konnte ein dritter Platz errungen werden. 1990 gab Richard Wolf die Chorarbeit aus Altersgründen auf.</p>
            </ChronicalChoirDirection>
        </React.Fragment>
    )
}

export default chronicalSection

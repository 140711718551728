import React from 'react'
import { Switch, Route } from 'react-router-dom'

import Home from '../components/pages/Home/Home'
import Choir from '../components/pages/Choir/Choir'
import ChoirDirection from '../components/pages/ChoirDirection/ChoirDirection'
import Practice from '../components/pages/Practice/Practice'
import Media from '../components/pages/Media/Media'
import News from '../components/pages/News/News'
import Corona from '../components/pages/Corona/Corona'
import Impressum from '../components/pages/Impressum/Impressum'

const pageHandler = (props) => {
    return (
        <Switch>
            <Route path="/admin">
                <h1>Admin</h1>
            </Route>
            <Route path="/impressum">
                <Impressum />
            </Route>
            <Route path="/corona">
                <Corona />
            </Route>
            <Route path="/news">
                <News />
            </Route>
            <Route path="/media">
                <Media videoSection={props.videoSection} mute={props.mute}/>
            </Route>
            <Route path="/choir-director">
                <ChoirDirection />
            </Route>
            <Route path='/practice'>
                <Practice />
            </Route>
            <Route path="/choir">
                <Choir />
            </Route>
            <Route path="/">
                <Home videoSection={props.videoSection} mute={props.mute} home={props.home} toggleShowComplete={props.toggleShowComplete}/>
            </Route>
            <Route path='*' exact={true}>
                <Home videoSection={props.videoSection} mute={props.mute}/>
            </Route>
      </Switch>
    )
}

export default pageHandler

function importAll(r) {
    return r.keys().map(r);
  }
  
  const images = importAll(require.context('../assets/media/img/', false, /\.(png|jpe?g|svg)$/));

  export default images

  export const cardSection = importAll(require.context('../assets/media/img/cardSection/', false, /\.(png|jpe?g|svg)$/))

  export const galleryImages = importAll(require.context('../assets/media/img/gallery'))

  export const newsImages = importAll(require.context('../assets/media/img/news'));
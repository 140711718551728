import React from 'react'
import NewsArticle from './NewsArticle/NewsArticle'
import classes from './News.module.css';
import { newsImages } from '../../../containers/ImageHandler';

const news = () => {
    return (
        <div className={classes.News}>
            <h1 className="classicalFont">Aktuelles</h1>
            <NewsArticle
            title="Musik erzählt Geschichten"
            published="29.06.23">
                <iframe src="https://drive.google.com/file/d/1MEhBgI063uM-X6wlMbCdv1-bhiTnJPpr/preview" width="100%" height="480" allow="autoplay"></iframe>
                <iframe src="https://drive.google.com/file/d/1RJxfrw4qohT_DoioRHfW_i8QL4CC1U-b/preview" width="100%" height="480" allow="autoplay"></iframe>
            </NewsArticle>

            <NewsArticle
                title="Chorwochende Herbst 2021 in Bünsdorf"
                published="03.10.21"
                img={newsImages}>
                    <p>Endlich ist Singen und Begegnung in lockerer Atmosphäre wieder möglich. Mit dieser freudigen Stimmung trafen sich die CanticanerInnen in der wunderbarer Lage am Wittensee um ihr Weihnachtsprogramm mit intensiven Proben vorzubereiten.</p>
                    <p>Tagsüber wechselten ausgiebige Proben mit „walk and talk“,   gemütlichen Essensrunden und der Weiterplanung für das Jahr 2022 ab.</p>
                    <p>Abends traf man sich in gemütlicher Runde zum „Lass uns einfach mal drauflos singen!“ und ausgiebig schnacken.</p>
                    <p>Mit Lust auf ein „Weiter so“ konnte sich der Chor beruhigte in die Herbstferien entlassen.</p>
            </NewsArticle>

            <NewsArticle
                title="Wir machen Lieder - Macher"
                published="08.10.20">
                    <p>Die Lockerungen der Landesregierung für das Singen von Amateur-Chören haben bei Cantica für frische Impulse gesorgt. Während der Sommerzeit ist der Chor im Garten der Bugenhagengemeinde mit dem Singen unter den Corona - Bedingungen vorsichtig gestartet.</p>
                    <p>Nachdem das großartige Beethoven-Projekt zunächst zur Seite gelegt werden musste, hat sich Chorleiter Norbert Klose unter dem Arbeitstitel „Wir machen Lieder-Macher“ interessante Stücke von Liedermachern und spannend gesetzte Volkslieder ausgesucht.</p>
                    <p>Der Chor möchte ein Programm erarbeiten, das Aktualität und traditionellen Kulturerhalt verbindet und für das Publikum (hoffentlich bald wieder) kleine Überraschungen bereithält.</p>
            </NewsArticle>

            <NewsArticle 
                title="25 Jahre mit Chorleiter Norbert Klose -- Herzlichen Glückwunsch"
                published="03.10.20"
                >
                    <p>Seit Mai 1995 proben die CanticanerInnen unter der Leitung von Norbert Klose.</p>
                    <p>Im Rahmen einer kleinen Feierstunde (Corona- bedingt erst im September) haben die CanticanerInnen Ihren Chorleiter in den Mittelpunkt gerückt.</p>
                    <p>Die Mitglieder waren zahlreich erschienen und Gabriele Vach dankte ihrem Chorleiter mit einem prallgefüllten Präsentkorb für seine engagierte Zusammenarbeit mit Cantica. Symbolische Päckchen standen für die Eigenschaften Engagement, Geduld und Ideenvielfalt, die Norbert Klose auszeichnen. Lustiges und Nützliches aus dem Korb sollen die künftige Zusammenarbeit versüßen und begleiten.</p>
                    <p>Die Liste der anspruchsvollen Erarbeitungen und großen Konzerte aus dieser Zeit ist lang. Fotos aus all den Jahren dokumentieren die Freude an der intensiven Chorgemeinschaft.</p>
                    <p>25 Jahre haben zu einer engen Verbundenheit geführt und lassen Norbert Klose mit seiner Truppe hoffnungsvoll auf die Weiterführung zu Corona Zeiten und vor allen Dingen danach blicken.</p>
            </NewsArticle>

        </div>
    )
}

export default news

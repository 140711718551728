import React from 'react'
import classes from './ExampleListItem.module.css'

const exampleListItem = (props) => {
    const color = (props.color === "wh") ? classes.ExampleListItem__white : classes.ExampleListItem__brown;
    return (
        <div className={color}>
            <strong>{props.artist}</strong>- <span>{props.title}</span>
        </div>
    )
}

export default exampleListItem

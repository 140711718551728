import React from 'react'
import ChronicalSection from './ChronicalSection/ChronicalSection'

const choir = () => {
    return (
        <div>
            <ChronicalSection />
        </div>
    )
}

export default choir

import React from 'react'
import ExampleListItem from './ExampleListItem/ExampleListItem'

import classes from './ExampleList.module.css'

const list = [
    {
        artist: "Johannes Brahms",
        title: '"Liebeslieder" - "Zigeunerlieder"',
        mp3: ''
    },
    {
        artist: "Antonin Dvorak",
        title: '"Messe in D"',
        mp3: ''
    },
    {
        artist: "Ralf Grössler",
        title: '"Our Father in Heaven" (symphonische Rhapsodie)– "Prince of Peace"',
        mp3: ''
    },
    {
        artist: "Joseph Haydn",
        title: '"Die Schöpfung"',
        mp3: ''
    },
    {
        artist: "Dietrich Lohff",
        title: '"Requiem für einen polnischen Jungen" - Oratorium "Luther"',
        mp3: ''
    },
    {
        artist: "Ludwig Meinardus",
        title: '"Simon Petrus" - "Kantate auf Christi Geburt"',
        mp3: ''
    },
    {
        artist: "Felix Mendelsohn-Bartholdy",
        title: 'die Oratorien "Elias" und "Paulus"- "der 42.Psalm" – "Lobgesang"',
        mp3: ''
    },
    {
        artist: "Wolfgang Amadeus Mozart",
        title: '"Missa brevis in B"',
        mp3: ''
    },
    {
        artist: "Carl Orff",
        title: '"Carmina burana"',
        mp3: ''
    },
    {
        artist: "Francis Poulenc",
        title: '"Gloria"',
        mp3: ''
    },
    {
        artist: "John Rutter",
        title: '"Magnificat"',
        mp3: ''
    },
    {
        artist: "Gioachino Antonio Rossini",
        title: '"Messa di Gloria" - "Petite messe solennelle"',
        mp3: ''
    },
    {
        artist: "Camille Saint-Saens",
        title: '"Weihnachtsoratorium"',
        mp3: ''
    },
    {
        artist: "Ferdinand Thieriot",
        title: '"Stabat Mater" - "Te deum" - "der67.Psalm"',
        mp3: ''
    },
    {
        artist: "In 80 Minuten um die Welt",
        title: '',
        mp3: ''
    },
    {
        artist: "Cantica goes Hollywood",
        title: '',
        mp3: ''
    }
]

const ExampleList = () => {
    const completeList = list.map((listElement, index) =>{ 
    let elementColor = (index%2 === 0) ? "wh" : "br";
    return(
        <ExampleListItem
            key={listElement.title + index}
            color={elementColor}
            artist={listElement.artist}
            title={listElement.title}
        />
    )})
    return (
        <div className={classes.ExampleList}>
            <h2 className="classicalFont">Beispiele aus &uuml;ber 20 Jahren</h2>
            {completeList}
        </div>
    )
}

export default ExampleList

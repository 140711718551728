import React from 'react'
import classes from './NewsArticle.module.css'

const newsArticle = (props) => {
    let images;
    let imageContainer = null;
    if(props.img){
        images = props.img.map(element => <img src={element} alt={element}/>);
        imageContainer =  <div className={classes.NewsArticle__imageContainer}>{images}</div>
    }

    return (
        <div className={classes.NewsArticle}>
            <div className={classes.NewsArticle__titleContainer}>
                <h3 className="classicalFont">{props.title}</h3>
            </div>
            {/* <div className={classes.NewsArticle__imageContainer}>
                {images}
            </div> */}
            {imageContainer}
            <div className={classes.NewsArticle__text}>{props.children}</div>
            <span>Datum: {props.published}</span>
        </div>
    )
}

export default newsArticle
